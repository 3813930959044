import React, { useEffect, useState } from 'react';

import {
  SampleDetailsBodyProperties,
  SamplesDetailsBodyAllPropertiesType
} from './SampleDetailsBodyProperties/SampleDetailsBodyProperties';
import { SamplesDetailsBodyInfoRetrievalItem } from './SamplesDetailsBodyInfoRetrievalItem/SamplesDetailsBodyInfoRetrievalItem';

import { StyledText } from '@/components/lib';
import {
  StyledSamplesDetailsBodyItemContainer,
  StyledSamplesDetailsBodyItemColumnContainer,
  StyledSamplesDetailsBodyItemTag,
  StyledContent,
  StyledSamplesDetailsBodySectionsContainer,
  StyledSamplesDetailsBodyItemColumnContainerContent
} from './SamplesDetailsBodyItem.styles';

import { getMarkedText } from '@/helpers/utils/getMarkedText';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import {
  InteractionSchemaLlmPropertiesReasons,
  PerPropertySchema,
  PropertyConfigSchemaOutput,
  PropertyDefinitionSchema,
  StepSchema,
  StepSchemaType,
  StepType,
  TextEntrySchema,
  useGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet
} from '@/helpers/services/api';

import { SelectedColumnType } from '@/components/Samples/Samples.types';
import { constants as tableConstants } from '../../../../Samples/SamplesTable/samplesTable.constants';

interface SamplesDetailsBodyItemProps {
  appId?: number;
  section: StepSchema;
  isPentest?: boolean;
  appVersionId: number;
  orderingProps?: boolean;
  showProperties: boolean;
  isTranslation?: boolean;
  isMultiSection: boolean;
  isInfoRetrieval?: boolean;
  userInteractionId?: string;
  isTranslationExist?: boolean;
  llmReasons?: InteractionSchemaLlmPropertiesReasons;
  selectedColumns?: SelectedColumnType[];
  multiSectionContent: TextEntrySchema[] | undefined;
  properties: SamplesDetailsBodyAllPropertiesType;
  listOfProperties?: PropertyConfigSchemaOutput[];
  setSection: (section: string) => void;
  setIsTranslation: (isTranslation: boolean) => void;
  setShowProperties: (showProperties: boolean) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
}

const constants = {
  stepLabel: 'Step:',
  paramsWidth: '250px',
  parametersLabel: 'Parameters:',
  errorLabel: (err: string) => `Error=${err}`,
  typeFormatted: (type?: StepSchemaType) => `Type=${type ?? 'N/A'}`,
  inputLabel: (withInteractionPrefix?: boolean) => (withInteractionPrefix ? 'Interaction Input:' : 'Input:'),
  outputLabel: (withInteractionPrefix?: boolean) => (withInteractionPrefix ? 'Interaction Output:' : 'Output:')
};

const { inputLabel, outputLabel, parametersLabel, paramsWidth, stepLabel, errorLabel, typeFormatted } = constants;

export const SamplesDetailsBodyItem = (props: SamplesDetailsBodyItemProps) => {
  const {
    appId,
    section,
    isPentest,
    properties,
    llmReasons,
    appVersionId,
    isTranslation,
    orderingProps,
    showProperties,
    isMultiSection,
    isInfoRetrieval,
    selectedColumns,
    listOfProperties,
    userInteractionId,
    isTranslationExist,
    multiSectionContent,
    setSection,
    updateProperty,
    getPropertyInfo,
    setIsTranslation,
    setShowProperties
  } = props;

  const [propertyToMark, setPropertyToMark] = useState('');

  const isStep = !!section?.name;
  const isMainInteraction = !!section?.input && !!section?.output && !isStep;
  const isSingleView = !isStep && !!((!section?.input && section?.output) || (section?.input && !section?.output));
  const isOnlyOutputStep = (isStep && !section?.input && !!section?.output) || (!!section?.input && !section?.output);

  const { data: allMarkingDetails } =
    useGetInteractionPropertiesMetadataApiV1ApplicationVersionsApplicationVersionIdInteractionsUserInteractionIdPropertiesMetadataGet(
      appVersionId,
      `${userInteractionId}`
    );

  useEffect(() => {
    if (isTranslationExist && !isTranslation) {
      setPropertyToMark('');
    }
  }, [isTranslationExist, isTranslation]);

  const chosenPropertyToMarkDetails = Array?.isArray(allMarkingDetails)
    ? allMarkingDetails?.find(el => el?.name === propertyToMark)
    : ({} as PerPropertySchema);

  return (
    <StyledSamplesDetailsBodyItemContainer showProperties={showProperties}>
      <StyledSamplesDetailsBodySectionsContainer
        step={isStep || undefined}
        single={isSingleView ? 'true' : 'false'}
        is_info_retrieval={isMultiSection.toString()}
        is_only_output_step={isOnlyOutputStep ? 'true' : 'false'}
      >
        {section?.input && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledText text={inputLabel(isMainInteraction)} type="h3" />
            <StyledContent
              translation={isTranslation}
              text={getMarkedText(
                section?.input,
                chosenPropertyToMarkDetails?.element_to_mark === 'input' ? chosenPropertyToMarkDetails : undefined
              )}
            />
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
        {isMultiSection &&
          multiSectionContent?.map((el, index) => (
            <SamplesDetailsBodyInfoRetrievalItem
              key={index}
              index={index}
              infoRetrievalItem={el}
              isTranslation={isTranslation}
              markingDetails={
                chosenPropertyToMarkDetails?.metadata?.find(el => el?.document_index === index) && isInfoRetrieval
                  ? chosenPropertyToMarkDetails
                  : undefined
              }
            />
          ))}
        {!isMultiSection && section?.output && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledText text={outputLabel(isMainInteraction)} type="h3" />
            <StyledContent
              translation={isTranslation}
              text={getMarkedText(
                section?.output,
                chosenPropertyToMarkDetails?.element_to_mark === 'output' && isMainInteraction // isMainInteraction used to ignore fullPrompt.output marking
                  ? chosenPropertyToMarkDetails
                  : undefined
              )}
              important={section?.type === StepType['INFORMATION_RETRIEVAL']}
            />
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
        {isStep && (
          <StyledSamplesDetailsBodyItemColumnContainer>
            <StyledSamplesDetailsBodyItemColumnContainerContent>
              <StyledText text={stepLabel} type="h3" width={paramsWidth} />
              <StyledSamplesDetailsBodyItemTag text={section?.name} />
              <StyledSamplesDetailsBodyItemTag text={tableConstants?.stepId(section?.id)} />
              <StyledSamplesDetailsBodyItemTag text={typeFormatted(section?.type)} />
              {section?.error && <StyledSamplesDetailsBodyItemTag text={errorLabel(section?.error)} />}
              <StyledText text={parametersLabel} type="h3" width={paramsWidth} />
              {section?.attributes &&
                Object.keys(section?.attributes).map(key => (
                  <StyledSamplesDetailsBodyItemTag
                    key={key}
                    text={`${key}=${section?.attributes ? section?.attributes[key] : ''}`}
                  />
                ))}
            </StyledSamplesDetailsBodyItemColumnContainerContent>
          </StyledSamplesDetailsBodyItemColumnContainer>
        )}
      </StyledSamplesDetailsBodySectionsContainer>
      <SampleDetailsBodyProperties
        appId={appId}
        isPentest={isPentest}
        llmReasons={llmReasons}
        properties={properties}
        orderingProps={orderingProps}
        showProperties={showProperties}
        propertyToMark={propertyToMark}
        selectedColumns={selectedColumns}
        listOfProperties={listOfProperties}
        allMarkingDetails={allMarkingDetails}
        userInteractionId={userInteractionId}
        switchToTranslated={isTranslationExist && !isTranslation}
        setSection={setSection}
        updateProperty={updateProperty}
        getPropertyInfo={getPropertyInfo}
        setIsTranslation={setIsTranslation}
        setPropertyToMark={setPropertyToMark}
        setShowProperties={setShowProperties}
        ignoreReasoningTooltip
      />
    </StyledSamplesDetailsBodyItemContainer>
  );
};
